export default function Header() {
  return (
    <div className="header">
      <div className="logo-image"></div>
      <div className="app-title">
        <h1>Technology News-Flash</h1>
      </div>
    </div>
  );
}
